import * as THREE from 'three'
import React, { Suspense, useState } from 'react'
import { useFrame, useLoader, useThree } from '@react-three/fiber'
import { PerspectiveCamera, Bounds } from '@react-three/drei'
import { EffectComposer, DepthOfField, Bloom, Noise, Vignette } from '@react-three/postprocessing'
//import { LUTCubeLoader } from 'postprocessing'
import Logo from './components/Logo'

export default function App(props) {
	const [vec] = useState(() => new THREE.Vector3())
	const [width, height] = useAspect(1, 1)

	useFrame((state) => {
		//state.camera.fov = window.innerHeight / 100
		// console.log(state.camera.fov)
		state.camera.position.lerp(vec.set(state.mouse.x * 15, state.mouse.y * 15, 29), 0.03)
		state.camera.lookAt(0, 0, 0)
	})

	return (
		<>
			<color attach="background" args={['black']} />
			<fog attach="fog" args={['black', 0, 40]} />

			<PerspectiveCamera makeDefault position={[0, 0, 20]} fov={30} />

			<Suspense fallback={null}>
				<ambientLight intensity={1} />
				<directionalLight position={[0, 0, 2]} intensity={2} />
				<Logo position={[0.31, 3, 0]} scale={[width / 8, height / 8, 1]} />
			</Suspense>
		</>
	)
}

export function useAspect(width, height, factor = 1) {
	const { viewport: v, aspect } = useThree()
	const adaptedHeight = height * (aspect > width / height ? v.width / width : v.height / height)
	const adaptedWidth = width * (aspect > width / height ? v.width / width : v.height / height)
	return [adaptedWidth * factor, adaptedHeight * factor, 1]
}
