import { createRoot } from 'react-dom/client'
import React from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import App from './App'
import './styles.css'

function Overlay() {
	return (
		<>
			<div className={`absolute top-0 left-0 w-screen h-screen flex flex-row justify-center pointer-events-none`}>
				<div className={`w-48 md:w-80 py-40`}>
					<div>
						<img src={`./assets/images/logo.svg`} className={`py-5`} alt={``} />
					</div>
					<div className={`text-gray-200 text-opacity-100 font-gothamxlight font-normal text-xs md:text-base tracking-wider underline underline-offset-4 pointer-events-auto`}>
						<a href="mailto:info@lumiq.net">info@lumiq.net</a>
					</div>
				</div>
			</div>
		</>
	)
}

createRoot(document.querySelector('#root')).render(
	<>
		<Canvas dpr={[1, 2]}>
			<App />
		</Canvas>
		<Overlay />
	</>
)
