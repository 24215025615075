import React, { useRef, useEffect } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Logo({ ...props }) {
	const ref = useRef()
	const { nodes, materials } = useGLTF('./assets/models/logo-brand-mark.glb')

	materials.Q.roughness = 0.8
	materials.Q.transparent = false
	materials.Q.opacity = 0.5
	materials.Q.wireframe = false

	useEffect(() => {}, [])

	return (
		<group ref={ref} {...props} dispose={null}>
			<group rotation={[Math.PI / 4.5, 0, Math.PI / 4]}>
				<mesh clipintersection={true} castShadow receiveShadow geometry={nodes.L.geometry} material={materials.L} position={[-2.5, -2.5, 2]} scale={[1, 1, 4]} />
				<mesh castShadow receiveShadow geometry={nodes.Q.geometry} material={materials.Q} position={[1.5, -2.5, 2]} scale={[1, 1, 4]}></mesh>
			</group>
		</group>
	)
}

useGLTF.preload('./assets/models/logo-brand-mark.glb')
